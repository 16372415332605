var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    { staticClass: "layout-container platform-report-container" },
    [
      _c(
        "el-header",
        { staticClass: "platform-report-header" },
        [
          _c("div", { staticClass: "title-header-container" }, [
            _c("span", { staticClass: "title-header" }),
            _c("h4", { staticClass: "title-text" }, [_vm._v("审核报告")]),
          ]),
          _c(
            "el-form",
            { attrs: { model: _vm.tableSearchObj, inline: true } },
            [
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    attrs: {
                      clearable: "",
                      placeholder: "输入编号/药品通用名",
                    },
                    model: {
                      value: _vm.tableSearchObj.searchValue,
                      callback: function ($$v) {
                        _vm.$set(_vm.tableSearchObj, "searchValue", $$v)
                      },
                      expression: "tableSearchObj.searchValue",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        size: "small",
                        type: "primary",
                        icon: "el-icon-search",
                      },
                      on: { click: _vm.onSearchReportTable },
                    },
                    [_vm._v("查询")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "批量评审" } },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-color": "#2c5ff9",
                      "inactive-color": "#b5ccf5",
                    },
                    on: { change: _vm.batchAssessChange },
                    model: {
                      value: _vm.isBatch,
                      callback: function ($$v) {
                        _vm.isBatch = $$v
                      },
                      expression: "isBatch",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "筛选" } },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-color": "#2c5ff9",
                      "inactive-color": "#b5ccf5",
                    },
                    on: { change: _vm.filterChange },
                    model: {
                      value: _vm.isFilter,
                      callback: function ($$v) {
                        _vm.isFilter = $$v
                      },
                      expression: "isFilter",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.onFilterReset },
                    },
                    [_vm._v("筛选重置")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.onPublishReport },
                    },
                    [_vm._v("发布报告")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-main",
        { staticClass: "platform-report-main" },
        [
          _c(
            "win-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableLoading,
                  expression: "tableLoading",
                },
              ],
              ref: "reportTable",
              staticClass: "platform-report-table",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                "header-cell-class-name": _vm.headerGradeInfo,
                "row-class-name": _vm.tabRowClassName,
                "cell-class-name": _vm.gradeInfoCellClass,
                "header-cell-style": { textAlign: "center" },
                "span-method": _vm.getSummaries,
                "row-merge-number": 2,
                "row-merge-hover-key": "drugId",
                "row-key": "drugIdFrom",
                height: "100%",
                border: "",
              },
              on: {
                "cell-click": _vm.tableClick,
                "current-change": _vm.currentChange,
                "cell-mouse-enter": _vm.cellMouseEnter,
                "cell-mouse-leave": _vm.cellMouseLeave,
              },
            },
            [
              _vm._l(_vm.tableColumsList, function (item) {
                return [
                  item.show && !item.isTypeTag
                    ? _c("el-table-column", {
                        key: item.id,
                        staticClass: "none-border",
                        attrs: {
                          width: item.width,
                          minWidth: item.minWidth,
                          prop: item.prop,
                          fixed: item.fixed,
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "header",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "clearfix",
                                      style:
                                        scope.column.property === "drugId"
                                          ? "text-align: left"
                                          : "text-align: center",
                                    },
                                    [
                                      _vm.showBatchCheck(scope)
                                        ? _c("el-checkbox", {
                                            staticStyle: {
                                              "margin-left": "5px",
                                            },
                                            attrs: {
                                              indeterminate:
                                                _vm.isIndeterminate,
                                            },
                                            on: { change: _vm.batchAllChange },
                                            model: {
                                              value: _vm.batchAll,
                                              callback: function ($$v) {
                                                _vm.batchAll = $$v
                                              },
                                              expression: "batchAll",
                                            },
                                          })
                                        : _vm._e(),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                        },
                                        [_vm._v(_vm._s(item.name))]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm.showBatchCheck(scope)
                                    ? _c("el-checkbox", {
                                        staticClass: "fl",
                                        on: { change: _vm.batchChange },
                                        model: {
                                          value: scope.row.checked,
                                          callback: function ($$v) {
                                            _vm.$set(scope.row, "checked", $$v)
                                          },
                                          expression: "scope.row.checked",
                                        },
                                      })
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "clearfix",
                                      staticStyle: { display: "inline-block" },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "fl",
                                          class: _vm.showLinkClass(scope),
                                          staticStyle: { margin: "0 5px" },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.onShowSimpleOperation(
                                                scope
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                scope.row[scope.column.property]
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _vm.isShowErrorDetails(scope)
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "error-details fr",
                                              staticStyle: { margin: "0 5px" },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    scope.row[
                                                      scope.column.property +
                                                        "errorTypeStr"
                                                    ]
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      })
                    : item.isTypeTag && !item.show
                    ? _c("el-table-column", {
                        key: item.id,
                        attrs: {
                          fixed: item.fixed,
                          prop: item.prop,
                          width: item.width,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "header",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        display: "inline-block",
                                        width: "3px",
                                        height: "3px",
                                      },
                                    },
                                    [_vm._v(_vm._s(item.name))]
                                  ),
                                ]
                              },
                            },
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "span",
                                    { staticClass: "fl" },
                                    [
                                      scope.$index % 2 === 0
                                        ? _c(
                                            "el-popover",
                                            {
                                              attrs: {
                                                placement: "top-start",
                                                title: "",
                                                width: "170",
                                                "popper-class":
                                                  "type-icon-popper",
                                                trigger: "hover",
                                                content: "此行评审为机构数据",
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "el-icon-insti",
                                                attrs: { slot: "reference" },
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.typeClick(scope)
                                                  },
                                                },
                                                slot: "reference",
                                              }),
                                            ]
                                          )
                                        : _c(
                                            "el-popover",
                                            {
                                              attrs: {
                                                placement: "top-start",
                                                title: "",
                                                width: "170",
                                                "popper-class":
                                                  "type-icon-popper",
                                                trigger: "hover",
                                                content: "此行评审为平台数据",
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "el-icon-platform",
                                                attrs: { slot: "reference" },
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.typeClick(scope)
                                                  },
                                                },
                                                slot: "reference",
                                              }),
                                            ]
                                          ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      })
                    : !item.show && !item.isTypeTag
                    ? _c("el-table-column", {
                        key: item.id,
                        attrs: {
                          width: item.width,
                          prop: item.prop,
                          index: item.id,
                          fixed: item.fixed,
                          isFiltered: item.isFiltered,
                          "column-key": "isFiltered",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "header",
                              fn: function (scope) {
                                return [
                                  _c("span", {
                                    staticStyle: {
                                      display: "inline-block",
                                      width: "3px",
                                      height: "3px",
                                    },
                                  }),
                                  _c(
                                    "el-popover",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.isBatch,
                                          expression: "isBatch",
                                        },
                                      ],
                                      ref:
                                        "batchGradePopover" +
                                        scope.column.property +
                                        scope.$index,
                                      refInFor: true,
                                      attrs: {
                                        trigger: "manual",
                                        placement: "bottom",
                                        offset: 0,
                                        "popper-options": {
                                          boundariesElement: "body",
                                          offset: [0, 0],
                                          tether: false,
                                        },
                                        "visible-arrow": true,
                                        "popper-class": "governance-poper",
                                      },
                                      on: {
                                        hide: function ($event) {
                                          return _vm.onHidePopover(
                                            scope,
                                            "batch-grade"
                                          )
                                        },
                                        show: function ($event) {
                                          return _vm.onShowPopover(
                                            scope,
                                            "batch-grade"
                                          )
                                        },
                                      },
                                      model: {
                                        value:
                                          _vm.batchGradeObj[
                                            scope.column.property +
                                              "visibleBatchPopover"
                                          ],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.batchGradeObj,
                                            scope.column.property +
                                              "visibleBatchPopover",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "batchGradeObj[scope.column.property + 'visibleBatchPopover']",
                                      },
                                    },
                                    [
                                      _vm.batchGradeObj[
                                        scope.column.property +
                                          "visibleBatchPopover"
                                      ]
                                        ? _c("planar-grade", {
                                            ref:
                                              "batch-grade" +
                                              scope.column.property +
                                              scope.$index,
                                            refInFor: true,
                                            attrs: {
                                              "show-filter": _vm.isFilter,
                                              "is-batch": true,
                                              "filter-options":
                                                _vm.gradeOptions,
                                              "problem-type":
                                                _vm.preblemTypeProp(scope),
                                              "risk-level":
                                                _vm.riskLevelProp(scope),
                                            },
                                            on: {
                                              closeFilter:
                                                _vm.onCloseGradeFilter,
                                            },
                                          })
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        {
                                          attrs: { slot: "reference" },
                                          slot: "reference",
                                        },
                                        [
                                          _c("svg-icon", {
                                            staticClass: "filter-icon",
                                            staticStyle: {
                                              position: "absolute",
                                              right: "24px",
                                              top: "50%",
                                              transform: "translateY(-50%)",
                                              width: "18px",
                                              height: "18px",
                                            },
                                            attrs: { "icon-class": "batch" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.batchGradeClick(
                                                  scope
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.isFilter
                                    ? _c(
                                        "el-popover",
                                        {
                                          ref:
                                            "filterPopover" +
                                            scope.column.property +
                                            scope.$index,
                                          refInFor: true,
                                          attrs: {
                                            trigger: "click",
                                            placement: "right",
                                            "popper-class": "governance-poper",
                                            "visible-arrow": false,
                                          },
                                          on: {
                                            hide: function ($event) {
                                              return _vm.onHidePopover(
                                                scope,
                                                "filter-cascader"
                                              )
                                            },
                                            show: function ($event) {
                                              return _vm.onShowPopover(
                                                scope,
                                                "filter-cascader"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm.showFilterObj[
                                            scope.column.property + scope.$index
                                          ]
                                            ? _c("flat-filter", {
                                                ref:
                                                  "filter-cascader" +
                                                  scope.column.property +
                                                  scope.$index,
                                                refInFor: true,
                                                attrs: {
                                                  "show-filter": _vm.isFilter,
                                                  "filter-options":
                                                    _vm.filterOptions,
                                                  "is-level-merge": false,
                                                  column: scope,
                                                  position:
                                                    _vm.filterCascaderPosition(
                                                      scope
                                                    ),
                                                },
                                                on: {
                                                  closeFilter:
                                                    _vm.onCloseFilter,
                                                },
                                              })
                                            : _vm._e(),
                                          !_vm.filterChangeIcon[
                                            scope.column.property + scope.$index
                                          ]
                                            ? _c("svg-icon", {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: _vm.isFilter,
                                                    expression: "isFilter",
                                                  },
                                                ],
                                                staticClass: "filter-icon",
                                                staticStyle: {
                                                  position: "absolute",
                                                  right: "0px",
                                                  top: "0",
                                                  width: "20px",
                                                  height: "20px",
                                                },
                                                attrs: {
                                                  slot: "reference",
                                                  "icon-class": "shaixuan",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.onFilterClick(
                                                      scope,
                                                      "init"
                                                    )
                                                  },
                                                },
                                                slot: "reference",
                                              })
                                            : _vm.filterChangeIcon[
                                                scope.column.property +
                                                  scope.$index
                                              ]
                                            ? _c("svg-icon", {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: _vm.isFilter,
                                                    expression: "isFilter",
                                                  },
                                                ],
                                                staticClass: "filter-icon",
                                                staticStyle: {
                                                  position: "absolute",
                                                  right: "0x",
                                                  top: "0",
                                                  width: "20px",
                                                  height: "20px",
                                                },
                                                attrs: {
                                                  slot: "reference",
                                                  "icon-class": "filter",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.onFilterClick(
                                                      scope,
                                                      "selected"
                                                    )
                                                  },
                                                },
                                                slot: "reference",
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row[scope.column.property] !== "1"
                                    ? _c(
                                        "el-popover",
                                        {
                                          ref:
                                            "gradePopover" +
                                            scope.column.property +
                                            scope.$index,
                                          refInFor: true,
                                          attrs: {
                                            trigger: "click",
                                            placement: "bottom",
                                            "visible-arrow": false,
                                            "popper-class": "governance-poper",
                                          },
                                          on: {
                                            hide: function ($event) {
                                              return _vm.onHidePopover(
                                                scope,
                                                "grade-cascader"
                                              )
                                            },
                                            show: function ($event) {
                                              return _vm.onShowPopover(
                                                scope,
                                                "grade-cascader"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm.showGradeComponent(scope)
                                            ? _c("planar-grade", {
                                                ref:
                                                  "grade-cascader" +
                                                  scope.column.property +
                                                  scope.$index,
                                                refInFor: true,
                                                attrs: {
                                                  status:
                                                    scope.row[
                                                      scope.column.property
                                                    ],
                                                  "show-filter": _vm.isFilter,
                                                  currentClickLevel:
                                                    scope.row[
                                                      scope.column.property
                                                    ],
                                                  "filter-options":
                                                    _vm.gradeOptions,
                                                  "problem-type":
                                                    _vm.preblemTypeProp(scope),
                                                  "risk-level":
                                                    _vm.riskLevelProp(scope),
                                                  position:
                                                    _vm.gradePosition[
                                                      scope.column.property +
                                                        "" +
                                                        scope.row.drugId +
                                                        "" +
                                                        scope.$index
                                                    ],
                                                },
                                                on: {
                                                  closeFilter:
                                                    _vm.onCloseGradeFilter,
                                                },
                                              })
                                            : _vm._e(),
                                          _c(
                                            "div",
                                            {
                                              attrs: { slot: "reference" },
                                              slot: "reference",
                                            },
                                            [
                                              _c("svg-icon", {
                                                staticClass: "filter-icon",
                                                staticStyle: {
                                                  position: "absolute",
                                                  right: "16px",
                                                  top: "50%",
                                                  transform: "translateY(-50%)",
                                                  width: "18px",
                                                  height: "18px",
                                                },
                                                attrs: {
                                                  "icon-class": _vm.gradeIcon(
                                                    scope.row[
                                                      scope.column.property
                                                    ]
                                                  ),
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.gradeIconClick(
                                                      scope
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : scope.row[scope.column.property] === "1"
                                    ? _c("svg-icon", {
                                        staticClass: "filter-icon",
                                        staticStyle: {
                                          position: "absolute",
                                          right: "16px",
                                          top: "50%",
                                          transform: "translateY(-50%)",
                                          width: "18px",
                                          height: "18px",
                                        },
                                        attrs: {
                                          "icon-class": _vm.gradeIcon(
                                            scope.row[scope.column.property]
                                          ),
                                          "fill-color": "#0073E9",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.gradeIconClick(scope)
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      })
                    : _vm._e(),
                ]
              }),
              _c(
                "template",
                { staticStyle: { height: "100%" }, slot: "empty" },
                [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/images/no-booking.svg"),
                      alt: "",
                    },
                  }),
                  _c("p", [_vm._v("暂无数据")]),
                ]
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "el-footer",
        { staticStyle: { height: "42px" } },
        [
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.total > 0,
                expression: "total > 0",
              },
            ],
            attrs: {
              total: _vm.total,
              page: _vm.tableSearchObj.pageNum,
              limit: _vm.tableSearchObj.pageSize,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.tableSearchObj, "pageNum", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.tableSearchObj, "pageSize", $event)
              },
              pagination: _vm.getTableHandle,
            },
          }),
        ],
        1
      ),
      _vm.dialogVisible
        ? _c("simple-check-report-vue", {
            attrs: {
              "dialog-visible": _vm.dialogVisible,
              "drug-id": _vm.currentRowBySimple.drugId,
              "origin-table-data": _vm.initTableData,
              "origin-data": _vm.currentRowBySimple,
              "grade-options": _vm.gradeOptions,
              "problem-options": _vm.problemOptions,
              "table-search-obj": _vm.tableSearchObj,
            },
            on: {
              "update:dialogVisible": function ($event) {
                _vm.dialogVisible = $event
              },
              "update:dialog-visible": function ($event) {
                _vm.dialogVisible = $event
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }