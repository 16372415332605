<!--平台审核报告操作的表格-->
<template>
  <el-container class="layout-container platform-report-container">
    <el-header class="platform-report-header">
      <div class="title-header-container">
        <span class="title-header"></span>
        <h4 class="title-text">审核报告</h4>
      </div>
      <el-form :model="tableSearchObj" :inline="true">
        <el-form-item>
          <el-input v-model="tableSearchObj.searchValue" clearable placeholder="输入编号/药品通用名" />
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" icon="el-icon-search" @click="onSearchReportTable">查询</el-button>
        </el-form-item>
        <el-form-item label="批量评审">
          <el-switch
            v-model="isBatch"
            active-color="#2c5ff9"
            inactive-color="#b5ccf5"
            @change="batchAssessChange">
          </el-switch>
        </el-form-item>
        <el-form-item label="筛选">
          <el-switch
            v-model="isFilter"
            active-color="#2c5ff9"
            inactive-color="#b5ccf5"
            @change="filterChange">
          </el-switch>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" @click="onFilterReset">筛选重置</el-button>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" @click="onPublishReport">发布报告</el-button>
        </el-form-item>
        <!-- <el-form-item>
          <el-button size="small" type="primary" @click="columnSettings">列表自定义</el-button>
        </el-form-item> -->
      </el-form>
    </el-header>
    <el-main class="platform-report-main">
      <win-table
        ref="reportTable"
        :data="tableData"
        :header-cell-class-name="headerGradeInfo"
        :row-class-name="tabRowClassName"
        :cell-class-name="gradeInfoCellClass"
        :header-cell-style="{textAlign:'center'}"
        :span-method="getSummaries"
        :row-merge-number="2"
        row-merge-hover-key="drugId"
        row-key="drugIdFrom"
        v-loading="tableLoading"
        style="width: 100%;"
        height="100%"
        class="platform-report-table"
        border
        @cell-click="tableClick"
        @current-change="currentChange"
        @cell-mouse-enter="cellMouseEnter"
        @cell-mouse-leave="cellMouseLeave">
        <template v-for="item in tableColumsList">
          <el-table-column
            v-if="item.show && !item.isTypeTag"
            :key="item.id"
            :width="item.width"
            :minWidth="item.minWidth"
            :prop="item.prop"
            :fixed="item.fixed"
            show-overflow-tooltip
            class="none-border">
            <template slot="header" slot-scope="scope">
              <div :style="scope.column.property === 'drugId' ? 'text-align: left' : 'text-align: center'" class="clearfix">
                <!--全选框-->
                <el-checkbox
                  v-if="showBatchCheck(scope)"
                  :indeterminate="isIndeterminate"
                  v-model="batchAll"
                  @change="batchAllChange"
                  style="margin-left: 5px;"/>
                <span style="margin-left: 10px;">{{ item.name }}</span>
              </div>
            </template>
            <!--除了药品编码的非筛选模式列-->
            <template slot-scope="scope">
              <el-checkbox
                v-if="showBatchCheck(scope)"
                v-model="scope.row.checked"
                class="fl"
                @change="batchChange"/>
              <div style="display: inline-block;" class="clearfix">
                <span
                  style="margin: 0 5px;"
                  :class="showLinkClass(scope)"
                  class="fl"
                  @click.stop="onShowSimpleOperation(scope)">
                  {{ scope.row[scope.column.property] }}
                </span>
                <span v-if="isShowErrorDetails(scope)" class="error-details fr" style="margin: 0 5px;">
                  {{scope.row[scope.column.property + 'errorTypeStr']}}
                </span>
              </div>
            </template>
          </el-table-column>
          <!--标记是平台还是机构数据-->
          <el-table-column
            v-else-if="item.isTypeTag && !item.show"
            :fixed="item.fixed"
            :prop="item.prop"
            :width="item.width"
            :key="item.id">
            <template slot="header" slot-scope="scope">
              <span style="display: inline-block;width: 3px;height: 3px;">{{ item.name }}</span>
            </template>
            <template slot-scope="scope">
              <span class="fl">
                <el-popover
                  v-if="scope.$index % 2 === 0"
                  placement="top-start"
                  title=""
                  width="170"
                  popper-class="type-icon-popper"
                  trigger="hover"
                  content="此行评审为机构数据">
                  <i class="el-icon-insti" slot="reference" @click.stop="typeClick(scope)"></i>
                </el-popover>
                <el-popover
                  v-else
                  placement="top-start"
                  title=""
                  width="170"
                  popper-class="type-icon-popper"
                  trigger="hover"
                  content="此行评审为平台数据">
                  <i class="el-icon-platform" slot="reference" @click.stop="typeClick(scope)"></i>
                </el-popover>
              </span>
            </template>
          </el-table-column>
          <!--打分和筛选模式-->
          <el-table-column
            v-else-if="!item.show && !item.isTypeTag"
            :width="item.width"
            :prop="item.prop"
            :index="item.id"
            :fixed="item.fixed"
            :isFiltered="item.isFiltered"
            :key="item.id"
            column-key='isFiltered'>
            <template slot="header" slot-scope="scope">
              <span style="display: inline-block;width: 3px;height: 3px;"></span>
              <!--批量评审-->
              <el-popover
                :ref="'batchGradePopover' + scope.column.property + scope.$index"
                v-model="batchGradeObj[scope.column.property + 'visibleBatchPopover']"
                v-show="isBatch"
                trigger="manual"
                placement="bottom"
                :offset="0"
                :popper-options="{ 
                  boundariesElement: 'body',
                  offset: [0, 0],
                  tether: false
                }"
                :visible-arrow="true"
                popper-class="governance-poper"
                @hide="onHidePopover(scope, 'batch-grade')"
                @show="onShowPopover(scope, 'batch-grade')">
                <planar-grade
                  :ref="'batch-grade' + scope.column.property + scope.$index"
                  :show-filter="isFilter"
                  v-if="batchGradeObj[scope.column.property + 'visibleBatchPopover']"
                  :is-batch="true"
                  :filter-options="gradeOptions"
                  :problem-type="preblemTypeProp(scope)"
                  :risk-level="riskLevelProp(scope)"
                  @closeFilter="onCloseGradeFilter" />
                <div slot="reference">
                  <svg-icon
                    icon-class="batch"
                    class="filter-icon"
                    style="position: absolute; right: 24px;top: 50%;transform: translateY(-50%);width: 18px;height: 18px;"
                    @click="batchGradeClick(scope)"/>
                </div>
              </el-popover>
              <!--筛选模式-->
              <el-popover
                :ref="'filterPopover' + scope.column.property + scope.$index"
                v-if="isFilter"
                trigger="click"
                placement="right"
                popper-class="governance-poper"
                :visible-arrow="false"
                @hide="onHidePopover(scope, 'filter-cascader')"
                @show="onShowPopover(scope, 'filter-cascader')">
                <!-- <filter-cascader
                  :ref="'filter-cascader' + scope.column.property + scope.$index"
                  v-if="showFilterObj[scope.column.property + scope.$index]"
                  :show-filter="isFilter"
                  :filter-options="filterOptions"
                  :column="scope"
                  :position="filterCascaderPosition(scope)"
                  @closeFilter="onCloseFilter" /> -->
                <flat-filter
                  :ref="'filter-cascader' + scope.column.property + scope.$index"
                  v-if="showFilterObj[scope.column.property + scope.$index]"
                  :show-filter="isFilter"
                  :filter-options="filterOptions"
                  :is-level-merge="false"
                  :column="scope"
                  :position="filterCascaderPosition(scope)"
                  @closeFilter="onCloseFilter" />
                <svg-icon
                  slot="reference"
                  icon-class="shaixuan"
                  class="filter-icon"
                  v-if="!filterChangeIcon[scope.column.property + scope.$index]"
                  v-show="isFilter"
                  style="position: absolute; right: 0px;top: 0;width: 20px;height: 20px;"
                  @click="onFilterClick(scope, 'init')"/>
                <svg-icon
                  slot="reference"
                  icon-class="filter"
                  class="filter-icon"
                  v-else-if="filterChangeIcon[scope.column.property + scope.$index]"
                  v-show="isFilter"
                  style="position: absolute; right: 0x;top: 0;width: 20px;height: 20px;"
                  @click="onFilterClick(scope, 'selected')"/>
              </el-popover>
            </template>
            <template slot-scope="scope">
              <!--打分（成功、疑问、失败）-->
              <!-- <el-popover
                :ref="'gradePopover' + scope.column.property + scope.$index"
                trigger="click"
                placement="bottom"
                :visible-arrow="false"
                popper-class="governance-poper"
                @hide="onHidePopover(scope, 'grade-cascader')"
                @show="onShowPopover(scope, 'grade-cascader')">
                <grade-cascader
                  :ref="'grade-cascader' + scope.column.property + scope.$index"
                  :status="scope.row[scope.column.property]"
                  :show-filter="isFilter"
                  :filter-options="gradeOptions"
                  :problem-type="preblemTypeProp(scope)"
                  :risk-level="riskLevelProp(scope)"
                  :position="gradePosition[scope.column.property +''+ scope.row.drugId + '' + scope.$index]"
                  v-if="gradeCascaderShowFlag[scope.column.property+''+scope.row.drugId]"
                  @closeFilter="onCloseGradeFilter" />
                <div slot="reference" @click="onGradeFilterClick(scope)">
                  <svg-icon
                    :icon-class="gradeIcon(scope.row[scope.column.property])"
                    class="filter-icon"
                    style="position: absolute; right: 16px;top: 50%;transform: translateY(-50%);width: 18px;height: 18px;"/>
                    <transition-group>
                      <i v-show="gradeIconFolded(scope)" key="1" class="el-icon-caret-bottom icon-class"/>
                      <i v-show="!gradeIconFolded(scope)" key="2" class="el-icon-caret-top icon-class"/>
                    </transition-group>
                </div>
              </el-popover> -->
              <!--第二版打分组件-->
              <el-popover
                :ref="'gradePopover' + scope.column.property + scope.$index"
                v-if="scope.row[scope.column.property] !== '1'"
                trigger="click"
                placement="bottom"
                :visible-arrow="false"
                popper-class="governance-poper"
                @hide="onHidePopover(scope, 'grade-cascader')"
                @show="onShowPopover(scope, 'grade-cascader')">
                <planar-grade
                  :ref="'grade-cascader' + scope.column.property + scope.$index"
                  :status="scope.row[scope.column.property]"
                  :show-filter="isFilter"
                  :currentClickLevel="scope.row[scope.column.property]"
                  :filter-options="gradeOptions"
                  :problem-type="preblemTypeProp(scope)"
                  :risk-level="riskLevelProp(scope)"
                  :position="gradePosition[scope.column.property +''+ scope.row.drugId + '' + scope.$index]"
                  v-if="showGradeComponent(scope)"
                  @closeFilter="onCloseGradeFilter" />
                <div slot="reference">
                  <svg-icon
                    :icon-class="gradeIcon(scope.row[scope.column.property])"
                    class="filter-icon"
                    style="position: absolute; right: 16px;top: 50%;transform: translateY(-50%);width: 18px;height: 18px;"
                    @click="gradeIconClick(scope)"/>
                </div>
              </el-popover>
              <!--显示的是问号, 点击疑问状态切换到对号-->
              <svg-icon
                v-else-if="scope.row[scope.column.property] === '1'"
                :icon-class="gradeIcon(scope.row[scope.column.property])"
                class="filter-icon"
                fill-color="#0073E9"
                style="position: absolute; right: 16px;top: 50%;transform: translateY(-50%);width: 18px;height: 18px;"
                @click="gradeIconClick(scope)"/>
            </template>
          </el-table-column>
        </template>
        <template slot="empty" style="height: 100%;">
          <img src="@/assets/images/no-booking.svg" alt="">
          <p>暂无数据</p>
        </template>
      </win-table>
    </el-main>
    <el-footer style="height: 42px;">
      <pagination
        v-show="total > 0"
        :total="total"
        :page.sync="tableSearchObj.pageNum"
        :limit.sync="tableSearchObj.pageSize"
        @pagination="getTableHandle"/>
    </el-footer>
    
    <simple-check-report-vue
      v-if="dialogVisible"
      :dialog-visible.sync="dialogVisible"
      :drug-id="currentRowBySimple.drugId"
      :origin-table-data="initTableData"
      :origin-data="currentRowBySimple"
      :grade-options="gradeOptions"
      :problem-options="problemOptions"
      :table-search-obj="tableSearchObj" />
  </el-container>
</template>
<script>
  import {
    CHECK_REPORT_QUERY_OBJ,
    UPDATE_COLUMN_OBJ
  } from '@/utils/dm/platformGovernanceReport/data'
  import {
    CHECK_REPORT_COLUMN,
    CHECK_REPORT_PROPERTY_COLUMN,
    CHECK_REPORT_STATUS_COLUMN
  } from '@/utils/dm/platformGovernanceReport/checkReportTable'
  import {
    getCheckRecordListDataApi,
    updateRecordDataApi,
    publishReportApi } from '@/api/dm/platformGovernanceReport'
  import {
    FILTER_OPTIONS,
    GRADE_OPTIONS,
    TABLE_DATA,
    TABLE_DATA_ORIGIN,
    TABLE_COLUMN_LIST,
    PROBLEM_OPTIONS,
    RISK_OPTIONS
  } from '@/utils/dm/institutionGovernanceReport/data'
  import {
    handleTableData
  } from '@/utils/dm/institutionGovernanceReport/handle.js'
  import { UCgetConfig } from '@/utils/columnSettings'
  import { GLOBAL_CONFIGURATION_CONFIGKEY } from '@/utils/constants'
  import FilterCascader from '@/views/dm/institutionGovernanceReport/components/FilterCascader'
  import GradeCascader from '@/views/dm/institutionGovernanceReport/components/GradeCascader'
  import PlanarGrade from '@/views/dm/institutionGovernanceReport/components/PlanarGrade'
  import FlatFilter from '@/views/dm/institutionGovernanceReport/components/FlatFilter'
  import SimpleCheckReportVue from './SimpleCheckReport.vue'
  export default {
    name: 'institutionGovernanceReport',
    components: {
      FilterCascader,
      GradeCascader,
      PlanarGrade, // 第二版打分组件
      FlatFilter, // 第二版筛选组件
      SimpleCheckReportVue
    },
    data() {
      return {
        checked: false,
        filterOptions: this.$deepClone(FILTER_OPTIONS),
        gradeOptions: this.$deepClone( GRADE_OPTIONS),
        problemOptions: this.$deepClone(PROBLEM_OPTIONS),
        riskOptions: this.$deepClone(RISK_OPTIONS),
        tableSearchObj: this.$deepClone(CHECK_REPORT_QUERY_OBJ),
        spanArr: [],
        pos: 0,
        isFilter: false, // 是否开启筛选模式
        isBatch: false, // 是否批量评审
        total: 0,
        tableColumsList: this.$deepClone(CHECK_REPORT_COLUMN),
        CHECK_REPORT_PROPERTY_COLUMN: this.$deepClone(CHECK_REPORT_PROPERTY_COLUMN),
        CHECK_REPORT_STATUS_COLUMN: this.$deepClone(CHECK_REPORT_STATUS_COLUMN),
        currentRow: null, // 当前行
        tableData: [],
        cloneTableData: [],
        initTableData: [], // 表格获取到的接口数据，没有进行处理的
        currentRowItemByGrade: null, // 当前操作的表格数据
        currentScopeItemByGrade: null, // 当前打分操作scope
        gradePosition: {}, // 打分的位置
        updateColumnObj: this.$deepClone(UPDATE_COLUMN_OBJ), // 修改
        sltConfigKey: '', // 物质管理-物质列表-表格配置
        sltConfigClassify: '',
        tableLoading: false,
        isIndeterminate: false,
        batchAll: false, // 是否全选
        currentScope: null, // 点击的当前scope
        visibleBatchPopover: false,
       /* 控制每个单元格中的 治理报告打分级联 组件是否渲染*/
        gradeCascaderShowFlag: {},
        filterChangeIcon: {}, // 用来切换每个对应的筛选图标
        showFilterObj: {},
        batchGradeObj: {}, // 用来存储批量评审
        filterRefsArr: [], // 用来存储筛选模式组件
        dialogVisible: false,
        currentRowBySimple: null
      }
    },
    computed: {
      // tableData() {
      //   this.columnTableDataHandle() // 打分的图标初始为折叠状态
      //   return this.cloneTableData
      // },
      // batchGradeObj() {
      //   let obj = {}
      //   for (let item of this.tableColumsList) {
      //     if (item.prop && item.prop !== '' && item.prop) {
      //       obj[item.prop] = false
      //       obj[item.prop + 'visibleBatchPopover'] = false
      //     }
      //   }
      //   console.log(obj)
      //   return obj
      // },
      gradeIcon: function () {
        return function (status) {
          if (Number(status) === 3) {
            // 失败
            return 'kc-empty'
          } else if (Number(status) === 2) {
            // 成功
            return 'duihao2'
          } else if (Number(status) === 1) {
            // 疑问
            return 'wenhao'
          } else {
            return ''
          }
        }
      },
      // 是否显示风险级别或问题类型的具体详情
      isShowErrorDetails: function () {
        return function (scope) {
          const riskLevel = scope.row[scope.column.property + 'riskLevel']
          if ((riskLevel === '3' || riskLevel === '4') && scope.$index % 2 === 0) {
            // 只有机构那一条且满足条件数据显示
            return true
          } else {
            return false
          }
        }
      },
      // 打分的折叠和展开图表切换
      gradeIconFolded: function () {
        return function (scope) {
          // 例如：formNameCompareStatus，将Status截取掉
          const propertyLen = scope.column.property.length
          let prop = scope.column.property.substring(0, propertyLen - 6) + 'isFolded'
          if (scope.row[prop]) {
            return true
          } else {
            return false
          }
        }
      },
      // 筛选模式显示的位置
      filterCascaderPosition(scope) {
        return function(scope) {
          if (scope.$index > 10) {
            return 'left'
          } else {
            return 'right'
          }
        }
      },
      // 是否显示打分组件（第二版本）
      showGradeComponent() {
        return function (scope) {
          if (this.gradeCascaderShowFlag[scope.column.property+''+scope.row.drugId]) {
            return true
          } else {
            return false
          }
        }
      },
      // 打分显示的位置
      gradeCascaderPosition(scope) {
        return function(scope) {
          if (scope.column.index > 10) {
            return 'left'
          } else {
            return 'right'
          }
        }
      },
      // 打分传参：问题类型
      preblemTypeProp() {
        return function (scope) {
          const propertyLen = scope.column.property.length
          let prop = scope.column.property.substring(0, propertyLen - 6) + 'errorType'
          if (scope.row && scope.row[prop]) {
            console.log(scope.row[prop])
            return scope.row[prop]
          } else {
            // 表示status不是失败，为”成功“或者”疑问“
            return ''
          }
        }
      },
      // 打分传参：风险级别
      riskLevelProp() {
        return function (scope) {
          const propertyLen = scope.column.property.length
          let prop = scope.column.property.substring(0, propertyLen - 6) + 'riskLevel'
          if (scope.row && scope.row[prop]) {
            console.log(scope.row[prop])
            return scope.row[prop]
          } else {
            // 表示status不是失败，为”成功“或者”疑问“
            return ''
          }
        }
      },
      //
      showBatchCheck: {
        get(scope) {
          return function (scope) {
            if (scope.column.property === 'drugId' && this.isBatch) {
              let arr = []
              let counts = 0
              for (let key in this.batchGradeObj) {
                counts++
                if (this.batchGradeObj[key] && key.indexOf('visibleBatchPopover') <= -1) {
                  return true
                } else if (!this.batchGradeObj[key] && key.indexOf('visibleBatchPopover') <= -1) {
                  arr.push(key)
                }
              }
              if (arr.length === counts / 2) {
                return false
              }
              // return true
            } else {
              return false
            }
          }
        },
        set(newVal) {
          // return function (scope) {
          //   console.log(scope)
          //   if (scope.column.property === 'drugId') {
          //     return newVal
          //   }
          // }
          if (!newVal) {
            this.tableData.forEach(item => item.checked = false)
            this.batchAll = false
            this.isIndeterminate = false
          }
          return newVal
        }
      },
      // 在药品编码的
      showLinkClass() {
        return (scope) => {
          if (scope.column.property === 'drugId') {
            return 'link'
          }
        }
      }
    },
    watch: {
      'tableSearchObj.pageSize': function (newVal, oldVal) {
        for (let key in this.gradeCascaderShowFlag) {
          this.gradeCascaderShowFlag[key] = false
        }
        // this.gradeCascaderShowFlag = {}
      },
      // showBatchCheck: function(newVal, oldVal) {
      //   console.log(newVal)
      //   if (newVal) {
      //     this.tableData.forEach(item => item.checked = false)
      //     this.batchAll = false
      //   }
      // }
      showBatchCheck: {
        handler(newVal, oldVal) {
          // if (!newVal) {
          //   this.tableData.forEach(item => item.checked = false)
          //   this.batchAll = false
          // }
        },
        // immediate: true,
        deep: true
      }
    },
    async mounted() {
      this.$nextTick(async () => {
        await this.fetchData()
        this.tableDom = this.$refs.reportTable.bodyWrapper
        const tr = $(this.tableDom).find('tr')
        let _this = this
        // this.tableDom.addEventListener('mouseenter', this.cellMouseEnter)
        // this.tableDom.addEventListener('mouseleave', this.cellMouseLeave)
        
        this.tableDom.addEventListener('scroll', () => {
          // document.removeEventListener('mouseenter',this.cellMouseEnter)
          // document.removeEventListener('mouseeleave',this.cellMouseLeave)
          $(tr).find('td').each(function () {
            let tdDom = this
            // tdDom.addEventListener('mouseenter', _this.cellMouseEnter)
            // tdDom.addEventListener('mouseleave', _this.cellMouseLeave)
            $(tdDom).removeAttr('onmouseenter')
            $(tdDom).removeAttr('onmouseleave')
            // console.log(tdDom)
          })
          // console.log('scroll')
          // this.currentRow = null
          // 滚动的时候将打分组件或者筛选组件关闭
          // 滚动距离
          let scrollTop = this.tableDom.scrollTop
          // 变量windowHeight是可视区的高度
          let windowHeight = this.tableDom.clientHeight || this.tableDom.clientHeight
          // 变量scrollHeight是滚动条的总高度
          let scrollHeight = this.tableDom.scrollHeight || this.tableDom.scrollHeight
          /*if (scrollTop + windowHeight === scrollHeight) {
          // 获取到的不是全部数据 当滚动到底部 继续获取新的数据
          if (!this.allData) this.getMoreLog()
          console.log('scrollTop', scrollTop + 'windowHeight', windowHeight + 'scrollHeight', scrollHeight)
          } */
          /*if (this.currentScope) {
            const refName1 = 'batchGradePopover' + this.currentScope.column.property + this.currentScope.$index // 批量评审
            const refName2 = 'filterPopover' + this.currentScope.column.property + this.currentScope.$index // 筛选模式
            const refName3 = 'gradePopover' + this.currentScope.column.property + this.currentScope.$index // 打分
            this.$nextTick(() => {
              if (Array.isArray(this.$refs[refName1]) || Array.isArray(this.$refs[refName2]) || Array.isArray(this.$refs[refName3])) {
                this.$refs[refName1] && this.$refs[refName1][0] && this.$refs[refName1][0].doClose()
                this.$refs[refName1] && this.$refs[refName1][1] && this.$refs[refName1][1].doClose()
                this.$refs[refName2] && this.$refs[refName2][0] && this.$refs[refName2][0].doClose()
                this.$refs[refName2] && this.$refs[refName2][1] && this.$refs[refName2][1].doClose()
                this.$refs[refName3] && this.$refs[refName3][0] && this.$refs[refName3][0].doClose()
                this.$refs[refName3] && this.$refs[refName3][1] && this.$refs[refName3][1].doClose()
              } else {
                this.$refs[refName1] && this.$refs[refName1].doClose()
                this.$refs[refName2] && this.$refs[refName2].doClose()
                this.$refs[refName3] && this.$refs[refName3].doClose()
              }
              
            })
            // this.tableData.forEach(item => item.checked = false)
            this.onHidePopover(this.currentScope)
          } */
        })
      })
    },
    async created() {
      let item = GLOBAL_CONFIGURATION_CONFIGKEY.find(item => item.id === 55)
      this.sltConfigKey = item.configKey
      this.sltConfigClassify = item.configClassify
      /*this.getSettingTable().then(() => {
        this.$nextTick(async () => {
          // await this.fetchData()
          // this.setTableHeight()
          //页面初始化完毕，默认触发一次按照更新时间排序
          // this.$refs.refundTable.sort('id','descending');
        })
      })*/
    },
    methods: {
      typeClick(scope) {
        // console.log(scope)
      },
      getTableRowKey(row) {
        // console.log(row.drugIdFrom)
        return row.drugIdFrom
      },
      batchAllChange(val) {
        if (val) {
          // 当前页全选
          this.tableData.forEach(item => item.checked = true)
        } else {
          this.tableData.forEach(item => item.checked = false)
        }
        this.isIndeterminate = false
      },
      batchGradeClick(scope) {
        for (let key in this.batchGradeObj) {
          if (key.indexOf('visibleBatchPopover') > -1) {
            this.batchGradeObj[key] = false
          }
        }
        // this.batchGradeObj['genericNameCompare'] = true
        // this.batchGradeObj['genericNameCompare' + 'visibleBatchPopover'] = true
        this.batchGradeObj[scope.column.property] = true
        this.batchGradeObj[scope.column.property + 'visibleBatchPopover'] = true
        // this.$set(this.batchGradeObj, scope.column.property, true) // 批量评审的时候显示多选框
        // this.$set(this.batchGradeObj, scope.column.property + 'visibleBatchPopover', true) // 是否显示批量评审时显示的打分框
        // this.visibleBatchPopover = true
      },
      batchChange(val) {
        // console.log(this.tableData)
        // console.log(val)
        const arr = this.tableData.filter(item => item.checked)
        this.isIndeterminate = arr.length > 0 && arr.length < this.tableData.length / 2
        this.batchAll = arr.length === this.tableData.length / 2
      },
      // 打分事件
      async gradeIconClick(scope) {
        this.batchGradeObj[scope.column.property] = false
        this.batchGradeObj[scope.column.property + 'visibleBatchPopover'] = false
        this.showBatchCheck = false
        this.currentScopeItemByGrade = scope
        this.currentRowItemByGrade = scope.row
        this.currentScope = scope
        // 判断当前是对号，问好还是叉号
        const status = Number(scope.row[scope.column.property])
        if (status === 1) {
          // 疑问(点击疑问状态切换到对号)
          // this.gradeIcon = 'duihao2'
          await this.updateGrade(scope, '2')
        } else if (status === 2) {
          // 成功（点击成功状态切换到问号）
          this.onGradeFilterClick(scope)
        } else if (status === 3) {
          // 失败
          this.onGradeFilterClick(scope)
        }
      },
      // 打分修改调接口并处理数据
      async updateGrade(scope, status, selectObj) {
        // 6 就是status单词的长度
        let propertyLen = scope.column.property.length
        let fieldName = scope.column.property.substring(0, propertyLen - 6)
        this.updateColumnObj.applyId = this.tableSearchObj.applyId
        this.updateColumnObj.drugId = scope.row.drugId
        let drugIdArr = []
        for (let item of this.tableData) {
          if (item.checked) {
            drugIdArr.push(item.drugId)
          }
        }
        if (drugIdArr.length === 0) {
          // 单个修改
          this.updateColumnObj.drugIdSet = [scope.row.drugId]
        } else {
          // 批量修改
          this.updateColumnObj.drugIdSet = drugIdArr
        }
        this.updateColumnObj.fieldName = fieldName
        if (Number(status) !== 3) {
          // 打分项为成功和疑问的时候
          // finalItem.status = selectObj.parentItem.value
          this.updateColumnObj.status = status
        } else {
          this.updateColumnObj.status = status
          this.updateColumnObj.errorType = selectObj.checkList.problemList
          this.updateColumnObj.riskLevel = selectObj.checkList.riskList
        }
        let res = await updateRecordDataApi(this.updateColumnObj)
        if (res.code === 200) {
          // await this.fetchData()
          // 不调查询的接口，前端手动处理修改的这一条数据
          this.tableData.find(item => {
            if (item.drugId === scope.row.drugId) {
              item[scope.column.property] = status
              if (selectObj && selectObj.parentItem.value === '3') {
                // 打分项为叉号的时候修改对应的风险项和错误项字段
                item[fieldName + 'errorType'] = selectObj.checkList.problemList
                item[fieldName + 'errorTypeStr'] = this.problemOptions.find(item => item.value === problemList).label
                item[fieldName + 'riskLevel'] = selectObj.checkList.riskList
                item[fieldName + 'riskLevelStr'] = this.problemOptions.find(item => item.value === riskList).label
              } else {
                item[fieldName + 'errorType'] = null
                item[fieldName + 'errorTypeStr'] = null
                item[fieldName + 'riskLevel'] = null
                item[fieldName + 'riskLevelStr'] = null
              }
            }
          })
        }
      },
      tableClick(row, column, cell, event) {
        console.log(row)
        const scope = this.currentScopeItemByGrade
        if (!scope) {
          return
        }
        // const refName = 'grade-cascader' + column.property + scope.$index
        const pageWidth = document.body.clientWidth
        const pageHeight = document.body.clientHeight
        const left = event.x
        const top = event.y
        let position = 'left-center'
        if (pageWidth - left > 300 && pageHeight - top > 220) {
          position = 'right-center'
        } else if (pageWidth - left <= 300 && pageHeight - top > 220) {
          position = 'left-center'
        } else if (pageHeight - top <= 220) {
          position = 'top-center'
        }
        this.$set(this.gradePosition, column.property +''+ row.drugId + '' + scope.$index, position)
      },
      // 当单元格 hover 进入时会触发该事件
      cellMouseEnter(row, column, cell, event) {
        this.currentRow = row
        // console.log('mouseEnter')
      },
      cellMouseLeave(row, column, cell, event) {
        this.currentRow = null
        // console.log('mouseLeave')
      },
      currentChange(currentRow, oldCurrentRow) {
        // console.log(oldCurrentRow)
        // console.log(currentRow)
      },
      mousewheelHandle(e) {
        // console.log('mousewheelHandle')
        // console.log(e)
      },
      async fetchData(type) {
        this.tableLoading = true
        this.tableSearchObj.applyId = this.$route.query.applyId
        let obj = this.$deepClone(this.tableSearchObj)
        if (type === 'reset') {
          // 筛选重置
          obj.searchValue = ''
        }
        let res = await getCheckRecordListDataApi(obj)
        let tableData = []
        if (res.code === 200) {
          tableData = res.data.rows || []
          this.initTableData = this.$deepClone(res.data.rows)
          this.total = res.data.total
        }
        this.cloneTableData = handleTableData(tableData)
        for (let i = 0; i < this.cloneTableData.length; i++) {
          let item = this.cloneTableData[i]
          let nextItem = this.cloneTableData[i + 1]
          if (nextItem) {
            if (item.drugId === nextItem.drugId && i % 2 === 1) {
              item.isStripe = false
              nextItem.isStripe = false
            } else if (item.drugId === nextItem.drugId && i % 4 === 0) {
              item.isStripe = true
              nextItem.isStripe = true
            } else if (item.drugId === nextItem.drugId && i % 4 === 1) {
              item.isStripe = false
              nextItem.isStripe = false
            }
          }
        }
        this.columnTableDataHandle(undefined, 'fetchData') // 打分的图标初始为折叠状态
        this.setMergeArr(this.tableData)
        this.tableLoading = false
      },
      filterChange(val) {
        if (val) {
        }
      },
      // 批量评审
      batchAssessChange(val) {
        if (!val) {
          for (let key in this.batchGradeObj) {
            this.batchGradeObj[key] = false
          }
        }
      },
      setMergeArr(data) {　
        this.spanArr = []
        for (var i = 0; i < data.length; i++) {
          if (i === 0) {
            this.spanArr.push(1)
            this.pos = 0
          } else {
              // 判断当前元素与上一个元素是否相同
            if (data[i].ustdCode === data[i - 1].ustdCode) {
              this.spanArr[this.pos] += 1
              this.spanArr.push(0)
            } else {
              this.spanArr.push(1)
              this.pos = i
            }
          }
        }
      },
      getSummaries({ row, column, rowIndex, columnIndex }) {
        /* if (columnIndex === 0) { // 设置需要合并的列（索引）
          if (rowIndex % 2 === 0) { // 设置合并多少行
            return {
              rowspan: 2, // 需要合并的行数
              colspan: 1 // 需要合并的列数，设置为0，不显示该列
            };
          } else {
            return {
              rowspan: 0,
              colspan: 0
            };
          }
        } else if (columnIndex % 2 === 0) { // 合并第二列所有行
          const _row = this.spanArr[rowIndex]
          const _col = _row > 0 ? 1 : 0
          return {
            // rowspan: _row,
            // colspan: _col
            rowspan: 2,
            colspan: 1
          }
        } else {
          return {
            rowspan: 0,
            colspan: 0
          }
        } */
        if ((columnIndex % 2 === 1 && columnIndex !== 1) || columnIndex === 0) {
          if (rowIndex % 2 === 0) {
            return {
              rowspan: 2,
              colspan: 1
            };
          } else {
            return {
              rowspan: 0,
              colspan: 0
            };
          }
        }
      },
      headerGradeInfo({ row, column, rowIndex, columnIndex }) {
        if ((columnIndex % 2 === 0 && columnIndex !== 1) || (columnIndex === 0)) {
          return 'none-border'
        }
      },
      gradeInfoCellClass({ row, column, rowIndex, columnIndex }) {
        let color = this.tableDataHandle(row, column, rowIndex, columnIndex)
        let hover = ''
        // if (this.currentRow && this.currentRow.drugId === row.drugId) {
        //   hover = 'hover'
        // } else {
        //   hover = ''
        // }
        return color + ' ' + hover
      },
      // 表格行
      tabRowClassName({ row, rowIndex }) {
        // if (this.currentRow && row.drugId === this.currentRow.drugId) {
        //   return 'hover'
        // } else {
        //   return ''
        // }
        if (row.isStripe) {
          // 斑马线样式
          return 'stripe-row'
        } else {
          return ''
        }
      },
      // 表格每一个列对应的打分列进行数据处理
      columnTableDataHandle(scope, type) {
        // 当column有值的时候表示操作的是当前列，当前列item[key + 'isFolded'] = false
        if (scope) {
          const propertyLen = scope.column.property.length
          let prop = scope.column.property.substring(0, propertyLen - 6) + 'isFolded'
          scope.row[prop] = false // 展开
          return
        }
        for (let key of TABLE_COLUMN_LIST) {
          this.cloneTableData.forEach(item => {
            item[key + 'isFolded'] = true // 打分的图标初始时为折叠状态
          })
        }
        if (this.tableData.length > 0 && type !== 'closeFilter' && type !== 'fetchData') {
          for (let key of TABLE_COLUMN_LIST) {
            this.tableData.forEach(item => {
              item[key + 'isFolded'] = true // 打分的图标初始时为折叠状态
            })
          }
        } else {
          this.tableData = this.$deepClone(this.cloneTableData)
        }
        // let data = Object.assign(this.tableData, this.cloneTableData)
        // this.tableData = this.$deepClone(data)
        // this.tableData = this.$deepClone(this.cloneTableData)
      },
      // 处理表格数据，风险项和优化项要显示背景颜色
      tableDataHandle(row, column, rowIndex, columnIndex) {
        let item = this.tableData[rowIndex]
        if (column.property && column.property !== 'drugId') {
          const propName = row[column.property + 'riskLevel']
          // 风险级别为风险项显示红色，为优化项显示黄色
          if (propName === '3' && columnIndex % 2 === 0) {
            if (rowIndex % 2 === 0) {
              return 'error-color error-reason'
            }
            return 'error-color'
          } else if (propName === '4' && columnIndex % 2 === 0) {
            item.className = 'warning-color'
            if (rowIndex % 2 === 0) {
              return 'warning-color warning-reason'
            }
            return 'warning-color'
          } else if ((propName === '2' || propName === '1')  && i === rowIndex) {
            return ''
          }
        }
      },
      // 处理表格数据，风险项和优化项要显示背景颜色(优化的方法)
      tableDataHandlePro(row, column, rowIndex, columnIndex) {
        for (let i = 0; i < this.tableData.length; i++) {
          let item = this.tableData[i]
          let nextItem = this.tableData[i + 1]
          const {
            genericNameCompareStatus,
            specCompareStatus,
            formNameCompareStatus,
            manufacturerCompareStatus,
            approvalNoCompareStatus
          } = item
          // 当风险级别为风险项和优化项的时候，机构那一行显示详情
          if (column.property && column.property !== 'drugId') {
            const propName = row[column.property + 'riskLevel']
            // 风险级别为风险项显示红色，为优化项显示黄色
            if (propName === '3' && columnIndex % 2 === 0) {
              if (rowIndex % 2 === 0) {
                return 'error-color error-reason'
              }
              return 'error-color'
            } else if (propName === '4' && columnIndex % 2 === 0) {
              item.className = 'warning-color'
              if (rowIndex % 2 === 0) {
                return 'warning-color warning-reason'
              }
              return 'warning-color'
              // nextItem.className = 'warning-color'

            } else if ((propName === '2' || propName === '1')  && i === rowIndex) {
              return ''
            }
          }
        }
      },
      // 筛选模式使用的组件popover隐藏时的事件
      onHidePopover(scope, refName = 'filter-cascader') {
        // const filterName = 'filter' + scope.$index
        const filterName = refName + scope.column.property + scope.$index
        this.$nextTick(() => {
          this.columnTableDataHandle()
          if (this.$refs[filterName]) {
            if (Array.isArray(this.$refs[filterName])) {
              // 使用了v-for导致输出为数组
              this.$refs[filterName].filterOptions && this.$refs[filterName][0].filterOptions.forEach(option => {
                option.showChildren = false
              })
            } else {
              this.$refs[filterName].filterOptions && this.$refs[filterName].filterOptions.forEach(option => {
                option.showChildren = false
              })
            }
          }
          if (this.filterOptions) {
            this.filterOptions.forEach(option => {
              option.showChildren = false
            })
          }
        })
      },
      // 筛选模式使用的组件popover显示时的事件
      onShowPopover(scope, refName) {
        let scrollLeft = document.getElementsByClassName('el-table__body-wrapper')[0].scrollLeft
        // console.log(scrollLeft)
        this.currentScope = scope
        // const filterName = 'filter' + scope.column.property + scope.$index
        const filterName = refName + scope.column.property + scope.$index
        // console.log(scope)
        this.$nextTick(() => {
          if (this.$refs[filterName]) {
            if (Array.isArray(this.$refs[filterName])) {
              // 使用了v-for导致输出为数组
              // this.$refs[filterName][0].updatePopper()
              // this.$refs[filterName][1].updatePopper()
              this.$refs[filterName][0].checkList = []
              this.$refs[filterName][0].isShowOptions = true
              this.$refs[filterName][1].checkList = []
              this.$refs[filterName][1].isShowOptions = true
              this.$refs[filterName][0].initHandleData && this.$refs[filterName][0].initHandleData(scope)
              this.$refs[filterName][1].initHandleData && this.$refs[filterName][1].initHandleData(scope)
            } else {
              // this.$refs[filterName].updatePopper()
              this.$refs[filterName].checkList = []
              this.$refs[filterName].isShowOptions = true
              this.$refs[filterName].initHandleData && this.$refs[filterName].initHandleData(scope)
            }
            // this.$refs['batchGradePopover' + scope.column.property + scope.$index][0].doClose()
            if (this.$refs['batchGradePopover' + scope.column.property + scope.$index]) {
              // 批量评审
              // console.log(this.$refs[filterName][0])
              // this.$refs[filterName][0].updatePopper()
              const popoverDom = this.$refs['batchGradePopover' + scope.column.property + scope.$index]
              popoverDom[1].$refs.popper.style.display = 'none'
              // console.log(popoverDom[0].$refs.popper.style.transform.translateX)
              // 滚动轴拖动，打分框位置计算会不正确
              popoverDom[0].$refs.popper.style.left = - (scrollLeft) + 'px'
              if (scope.$index >= this.tableColumsList.length - 1) {
                popoverDom[0].$refs.popper.style.left = - (scrollLeft) - 220 + 'px'
              }
              // popoverDom[0].$refs.popper.style.transform.translateX = 'none'
            }
            
            // this.$refs['batchGradePopover' + scope.column.property + scope.$index][1].popperElm.style.display = 'none'
            // this.$refs['batchGradePopover' + scope.column.property + scope.$index][1].destroyPopper()
            // for (const key in this.$refs) {
            //   if (key.indexOf('batchGradePopover') > -1) {
            //     this.$refs[key] && this.$refs[key].doClose()
            //   }
            // }
          }
        })
      },
      // 筛选模式，点击筛选图标事件
      onFilterClick(scope) {
        // console.log(scope)
        this.currentScope = scope
        const filterName = 'filter-cascader' + scope.column.property + scope.$index
        this.$set(this.showFilterObj, scope.column.property + scope.$index, true)
        // this.$set(this.filterChangeIcon, scope.column.property + scope.$index, false)
        this.filterRefsArr.push(filterName)
        this.$nextTick(() => {
          if (this.$refs[filterName]) {
            if (Array.isArray(this.$refs[filterName])) {
              // 使用了v-for导致输出为数组
              this.$refs[filterName][0].onFilterClick(scope)
              this.$refs[filterName][1].onFilterClick(scope)
            } else {
              this.$refs[filterName].onFilterClick(scope)
            }
          }
        })
      },
      // 筛选模式下拉项关闭
      async onCloseFilter(selectObj, scope) {
        // 第二版本点击取消按钮的时候，selectObj为undefined
        let len1 = 0
        let len2 = 0
        let riskLevelCheckList = []
        let problemCheckList = []
        if (selectObj) {
          riskLevelCheckList = selectObj.riskLevelCheckList
          problemCheckList = selectObj.problemCheckList
        }
        if (selectObj && riskLevelCheckList && riskLevelCheckList.length) {
          len1 = selectObj.riskLevelCheckList.length
        }
        if (selectObj && problemCheckList && problemCheckList.length) {
          len2 = selectObj.problemCheckList.length
        }
        if (len1 > 0 || len2 > 0) {
          this.$set(this.filterChangeIcon, scope.column.property + scope.$index, true)
        }
        this.columnTableDataHandle(undefined, 'closeFilter') // 打分的图标初始为折叠状态
        // 筛选模式按钮换成另一种
        // const refName = 'popover' + scope.column.property + scope.$index
        const refName = 'filterPopover' + scope.column.property + scope.$index
        this.$nextTick(() => {
          if (Array.isArray(this.$refs[refName])) {
            // 使用了v-for导致输出为数组
            this.$refs[refName][0] && this.$refs[refName][0].doClose()
            this.$refs[refName][1] && this.$refs[refName][1].doClose()
          } else {
            this.$refs[refName] && this.$refs[refName].doClose()
          }
        })
        this.filterVisible = false
        let iconStatus = false
        if (this.filterChangeIcon[scope.column.property + scope.$index]) {
          // 表示当前的图标为实心的，即上一次筛选条件是不为空的
          iconStatus = true
        }
        if (len1 === 0 && len2 === 0) {
          // 第一版本的时候什么都没选择的时候和第二版本点击取消按钮的时候，不调接口，直接关闭
          // 第二版本选择问号或叉号的时候
          if ((!iconStatus && selectObj && !selectObj.level) ||(!iconStatus && !selectObj)) {
            // 第一版本的时候什么都没选择的时候和第二版本点击取消按钮的时候，不调接口，直接关闭
            return
          } else if (!iconStatus && selectObj) {
            // 第二版本选择问号或叉号的时候
            this.$set(this.filterChangeIcon, scope.column.property + scope.$index, true)
          }
        } else if (len1 === 0 && len2 === 0 && iconStatus) {
          this.$set(this.filterChangeIcon, scope.column.property + scope.$index, false)
        }
        let propertyLen = scope.column.property.length
        let filterFieldName = scope.column.property.substring(0, propertyLen - 6)
        this.tableSearchObj.filterFieldName = filterFieldName
        this.tableSearchObj.filterErrorTypeList = []
        this.tableSearchObj.filterRiskLevelList = []
        /*if (selectObj.parentItem.value === '1') {
          // 风险级别（3---风险项、4---优化项）
          this.tableSearchObj.filterRiskLevelList = selectObj.checkList
        } else if (selectObj.parentItem.value === '2') {
          // 问题类型（1.缺失，2，不准确，3.不完整，4.颗粒度大，5.不规范）
          this.tableSearchObj.filterErrorTypeList = selectObj.checkList
        }*/
        this.tableSearchObj.filterRiskLevelList = riskLevelCheckList
        this.tableSearchObj.filterErrorTypeList = problemCheckList
        this.tableSearchObj.pageNum = 1
        let obj = {
          filterFieldName: [],
          filterErrorTypeList: [],
          filterRiskLevelList: []
        }
        let isExited = false
        // 1.如果之前筛选条件包含某一列，现在这一列又筛选成为空条件；2.修改了某一列的筛选条件
        for (let i = 0; i < this.tableSearchObj.filterList.length; i++) {
          let item = this.tableSearchObj.filterList[i]
          if (item.filterFieldName === filterFieldName) {
            if (problemCheckList.length === 0 && riskLevelCheckList.length === 0 && !selectObj.level) {
              // 删除该项(第一版本筛选组件)
              this.tableSearchObj.filterList.splice(i, 1)
            } else {
              //修改该项
              if (selectObj.level) {
                if (Array.isArray(selectObj.level)) {
                  item.evalResultList = selectObj.level
                } else {
                  item.evalResultList = [selectObj.level]
                }
              }
              item.filterErrorTypeList = problemCheckList
              item.filterRiskLevelList = riskLevelCheckList
            }
            isExited = true
            break
          }
        }
        if (Array.isArray(selectObj.level)) {
          obj.evalResultList = selectObj.level
        } else {
          obj.evalResultList = [selectObj.level]
        }
        obj.filterFieldName = filterFieldName
        obj.filterErrorTypeList = selectObj.problemCheckList
        obj.filterRiskLevelList = selectObj.riskLevelCheckList
        if (!isExited) {
          this.tableSearchObj.filterList.push(obj)
        }
        await this.fetchData()
        /**
         * 因为现在筛选模式需要调后台接口
        //****** 筛选图标的那一列的prop = 厂商等字段+ Status ******/

        // item[scope.column.property + 'riskLevel']
       /* if (selectObj.parentItem.value === '1') {
          // 风险级别（3---风险项、4---优化项）
          let property = scope.column.property.substring(0, propertyLen - 6) + 'riskLevel'
          let tableData = this.cloneTableData.filter(item => {
            if (selectObj.checkList.includes(item[property])) {
              return item
            }
          })
          this.tableData = this.$deepClone(tableData)
        } else if (selectObj.parentItem.value === '2') {
          // 问题类型（1.缺失，2，不准确，3.不完整，4.颗粒度大，5.不规范）
          let property = scope.column.property.substring(0, propertyLen - 6) + 'errorType'
          // item[scope.column.property + 'errorType']
          let tableData = this.cloneTableData.filter(item => {
            if (selectObj.checkList.includes(item[property])) {
              return item
            }
          })
          this.tableData = this.$deepClone(tableData)
        }**/
      },
      // 筛选重置
      async onFilterReset() {
        if (!this.isFilter) {
          return
        }
        this.filterChangeIcon = {}
        this.tableSearchObj.filterFieldName = ''
        this.tableSearchObj.pageNum = 1
        this.tableSearchObj.filterErrorTypeList = []
        this.tableSearchObj.filterRiskLevelList = []
        this.tableSearchObj.filterList = []
        for (let filter of this.filterRefsArr) {
          if (Array.isArray(this.$refs[filter])) {
            // 使用了v-for导致输出为数组
            // console.log(this.$refs[filter])
            this.$refs[filter][0] && this.$refs[filter][0].onReset()
            this.$refs[filter][1] && this.$refs[filter][1].onReset()
          } else {
            this.$refs[filter] && this.$refs[filter].onReset()
          }
        }
        await this.fetchData('reset')
      },
      // 打分图标按钮
      onGradeFilterClick(scope) {
        // 点击时，才渲染当前单元格中的  治理报告打分级联 组件
        // 操作原本不存在的响应式属性时，使用 Vue.set(object, propertyName, value) 方法向嵌套对象添加响应式属性
        this.$set(this.gradeCascaderShowFlag, scope.column.property+''+scope.row.drugId, true)
        this.columnTableDataHandle(scope) // 当前打分图标展开
        this.currentRowItemByGrade = scope.row
        this.currentScopeItemByGrade = scope
        const refName = 'grade-cascader' + scope.column.property + scope.$index
        this.$nextTick(() => {
          if (this.$refs[refName]) {
            if (Array.isArray(this.$refs[refName])) {
              // 使用了v-for导致输出为数组
              this.$refs[refName][0] && this.$refs[refName][0].onFilterClick(scope)
              this.$refs[refName][1] && this.$refs[refName][1].onFilterClick(scope)
            } else {
              this.$refs[refName].onFilterClick(scope)
            }
          }
        })
      },
      // 打分下拉项关闭
      async onCloseGradeFilter(selectObj, scope) {
        // this.visibleBatchPopover = false
        // this.$set(this.batchGradeObj, scope.column.property + 'visibleBatchPopover', false)
        if (this.batchGradeObj[scope.column.property] && selectObj) {
          let arr = this.tableData.filter(item => item.checked)
          if (!arr || arr.length === 0) {
            this.$message({
              type: 'warning',
              message: '请至少勾选一条数据'
            })
            return
          }
        }
        this.batchGradeObj[scope.column.property] = false
        this.batchGradeObj[scope.column.property + 'visibleBatchPopover'] = false
        // this.$set(this.batchGradeObj, scope.column.property, false)
        // this.$set(this.batchGradeObj, 'drugId', false)
        this.currentScope = null
        // this.columnTableDataHandle()
        const refName = 'gradePopover' + scope.column.property + scope.$index
        this.$nextTick(() => {
          if (this.$refs[refName]) {
            if (Array.isArray(this.$refs[refName])) {
              // 使用了v-for导致输出为数组
              this.$refs[refName][0] && this.$refs[refName][0].doClose()
              this.$refs[refName][1] && this.$refs[refName][1].doClose()
            } else {
              this.$refs[refName] && this.$refs[refName].doClose()
            }
          }
        })
        this.filterVisible = false
        const propertyLen = scope.column.property.length
        if (selectObj) {
          await this.onUpdateGradeRecord(selectObj, scope)
        }
        this.showBatchCheck = false
        // 修改打分
      },
      // 打分修改
      async onUpdateGradeRecord(selectObj, scope) {
        // 处理数据

        // let item = this.initTableData.find(row => row.drugId === this.currentRowItemByGrade.drugId)
        // let finalItem = Object.assign(item, this.currentRowItemByGrade.applyId)
        // finalItem.applyId = this.tableSearchObj.applyId
        let problemList = ''
        let riskList = ''
        // 1.修改问题类型errorType,风险级别riskLevel；2.修改status，成功、疑问、失败
        let propertyLen = scope.column.property.length
        let fieldName = scope.column.property.substring(0, propertyLen - 6)
        this.updateColumnObj.applyId = this.tableSearchObj.applyId
        // this.updateColumnObj.drugId = this.currentRowItemByGrade.drugId
        this.updateColumnObj.fieldName = fieldName
        let drugIdArr = []
        // console.log(this.tableData)
        for (let item of this.tableData) {
          if (item.checked) {
            drugIdArr.push(item.drugId)
          }
        }
        if (drugIdArr.length === 0) {
          // 单个修改
          this.updateColumnObj.drugIdSet = [scope.row.drugId]
        } else {
          // 批量修改
          this.updateColumnObj.drugIdSet = drugIdArr
        }
        // console.log(this.updateColumnObj)
        if (selectObj.parentItem) {
          //第一个版本的打分显示以及操作
          problemList = selectObj.checkList.problemList
          riskList = selectObj.checkList.riskList
          if (selectObj.parentItem.value !== '3') {
            // 打分项为成功和疑问的时候
            finalItem.status = selectObj.parentItem.value
            this.updateColumnObj.status = selectObj.parentItem.value
          } else {
            this.updateColumnObj.status = selectObj.parentItem.value
            this.updateColumnObj.errorType = selectObj.checkList.problemList
            this.updateColumnObj.riskLevel = selectObj.checkList.riskList
          }
        } else {
          // 第二个版本的打分显示以及操作
          problemList = selectObj.problemList
          riskList = selectObj.riskList
          this.updateColumnObj.status = selectObj.level
          this.updateColumnObj.errorType = selectObj.problemList
          this.updateColumnObj.riskLevel = selectObj.riskList
        }
        
        let res = await updateRecordDataApi(this.updateColumnObj)
        if (res.code === 200) {
          // await this.fetchData()
          // 不调查询的接口，前端手动处理修改的这一条数据
          this.tableData.find(item => {
            for (let drugId of this.updateColumnObj.drugIdSet) {
              if (item.drugId === drugId) {
                item[scope.column.property] = (selectObj.parentItem && selectObj.parentItem.value) || selectObj.level
                if ((selectObj.parentItem && selectObj.parentItem.value === '3') || Number(selectObj.level) === 3) {
                  // 打分项为叉号的时候修改对应的风险项和错误项字段
                  item[fieldName + 'errorType'] = problemList
                  item[fieldName + 'errorTypeStr'] = this.problemOptions.find(item => item.value === problemList).label
                  item[fieldName + 'riskLevel'] = riskList
                  item[fieldName + 'riskLevelStr'] = this.problemOptions.find(item => item.value === riskList).label
                } else {
                  item[fieldName + 'errorType'] = null
                  item[fieldName + 'errorTypeStr'] = null
                  item[fieldName + 'riskLevel'] = null
                  item[fieldName + 'riskLevelStr'] = null
                }
              }
            }
          })
          // scope.row[scope.column.property] = selectObj.parentItem.value
        }
      },
      // 点击查询按钮
      async onSearchReportTable() {
        this.tableSearchObj.pageNum = 1
        await this.fetchData()
      },
      // 发布报告
      async onPublishReport() {
        let res = await publishReportApi({ applyId: this.tableSearchObj.applyId })
        if (res.code === 200) {
          // 发布报告成功，回到审核报告列表页面
          this.$message.success('发布报告成功')
          // 关闭当前tab页
          this.$store.dispatch('tagsView/delView', this.$route)
          this.$router.replace('/dm/platformGovernanceReport')
        }
      },
      // 分页查询
      async getTableHandle() {
        await this.fetchData()
      },
      // 列表自定义
      async columnSettings() {
        let item = GLOBAL_CONFIGURATION_CONFIGKEY.find(item => item.id === 55)
        this.sltConfigKey = item.configKey
        this.sltConfigClassify = item.configClassify
        let _this = this
        _this.$ColumnSettings.showColSettings(
          _this.sltConfigKey,
          _this.sltConfigClassify,
          _this.tableColumsList,
          _this.getSettingTable
        )
      },
      // 设置按钮-获取设置的数据
      async getSettingTable () {
        let _this = this
        let obj = {}
        let item = GLOBAL_CONFIGURATION_CONFIGKEY.find(item => item.id === 55)
        this.sltConfigKey = item.configKey // 药物管理-药物列表-表格配置
        this.sltConfigClassify = item.configClassify // 配置分类
        obj.configKey = this.sltConfigKey
        this.columnOptions = []
        let res = await UCgetConfig(obj)
        if (res.data  && res.data.configValue) {
          let list = JSON.parse(res.data.configValue).list
          if (list.length > 0) {
            list.forEach(function (item) {
              if (item.show === undefined || item.show === true) {
                _this.columnOptions.push(item)
              }
            })
          } else {
            _this.msgError('获取数据表格配置信息失败')
          }
        } else {
          let defaultConfig = this.tableColumsList
          defaultConfig.forEach(function (item) {
            if (item.show==undefined || item.show==true) {
              _this.columnOptions.push(item)
            }
          })
        }
        _this.$nextTick(() => {
          _this.$refs.reportTable.doLayout()
        })
      },
      onShowSimpleOperation(scope) {
        this.dialogVisible = true
        this.currentRowBySimple = scope.row
      }
    }
  }
</script>
<style lang="scss" scoped>
.platform-report-container {
  .platform-report-header {
    display: flex;
    flex-wrap: wrap;
    height: auto !important;
    min-height: 55px;
    justify-content: space-between;
    align-items: center;
    .title-header-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: 40px;
      .title-header {
        width: 4px;
        height: 18px;
        background: #0073E9;
        border-radius: 2px;
      }
      .title-text {
        display: inline-block;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 18px;
        margin-left: 5px;
        vertical-align: top;
      }
    }
    .el-form {
      .el-form-item {
        margin-bottom: 0 !important;
        ::v-deep.el-form-item__label {
          font-size: 14px;
          color: #333;
          font-weight: 400;
        }
      }
    }
  }
  .platform-report-main {
    padding: 0 20px 10px !important;
    .platform-report-table {
      ::v-deep.el-table__empty-block {
        position: relatvie;
        .el-table__empty-text {
          position: fixed;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      ::v-deep .link {
        &:hover {
          text-decoration: underline;
          cursor: pointer;
          color: #4B56F2;
        }
      }
      ::v-deep.el-table__fixed {
      /* height:auto !important;  */
        height: calc(100% - 12px) !important;
        bottom: 16px;
      }
      ::v-deep .stripe-row {
        background-color: #fafafa;
      }
      ::v-deep .el-table--border th.none-border, ::v-deep .el-table--border td.none-border {
        background-color: blue;
        border-right: none !important;
      }
      ::v-deep td.none-border, ::v-deep th.none-border {
        border-right: none !important;
      }
      ::v-deep td {
        padding: 5px 5px !important;
        position: relative;
        .el-icon-insti {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          &:before {
            content: '';
            display: inline-block;
            width: 18px;
            height: 18px;
            background-image: url('../../../../assets/images/insti-icon.png');
            background-repeat: no-repeat;
          }
        }
        .el-icon-platform {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          &:before {
            content: '';
            display: inline-block;
            width: 18px;
            height: 18px;
            background-image: url('../../../../assets/images/plat-icon.png');
            background-repeat: no-repeat;
          }
        }
      }
      ::v-deep td.error-color {
        background-color: #FFDBE1;
      }
      ::v-deep td.warning-color {
        background-color: #FFE9D1;
      }
      ::v-deep .error-reason, ::v-deep .warning-reason {
        .cell {
          position: relative;
          .error-details {
            display: inline-block;
            padding: 5px !important;
            color: #2B60F8;
            background-color: #D7E3FA;
            font-size: 14px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            text-align: center;
          }
        }
      }
      .icon-class {
        display: inline-block;
        position: absolute;
        right: 0px;
        transform: translateY(-50%);
        color: #2B60F8;
        cursor: pointer;
      }
    }
  }
}
</style>
